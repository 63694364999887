.container {
  position: relative;
}

.wrapper {
  background-color: #303030;
  background-color: rgb(0 0 0 / 50%);
  padding: 15px;
  border-radius: 10px;
  margin-top: 25px;
}

.title {
  position: absolute;
  top: -15px;
  left: 5px;
  margin-top: 0;
}

.title h2 {
  font-size: 1.5em;
  font-weight: 500;
  letter-spacing: 0.03rem;
}
