/* CONTAINER */

.container h1 {
  font-weight: 600;
  margin-top: 0;
}

.container h2 {
  letter-spacing: 0.03rem;
}

.container p small {
  color: #919191;
  font-weight: 700;
  letter-spacing: 0.08rem;
}

.container p {
  color: #eaeaea;
}

/* HEADER */

.header h1 {
  color: #121212 !important;
  line-height: inherit;
}

.header h1 span {
  background-color: #ffe400;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1200px) {
  .header h1 {
    font-size: 4.5em !important;
  }
}

.link {
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.08rem;
  text-decoration: none;
}

.link:active {
  color: #eaeaea;
}

.album img {
  width: 100%;
  margin-bottom: 20px;
}

.shadow {
  border: 1px solid #000;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.5);
}

.watch {
  display: table;
  padding: 5px;
  line-height: 15pt;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  background-color: #ffe400;
  color: #121212;
}

.youtube_img {
  border: 1px solid #000;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.5);
}
